import clsx from "clsx"
import { FC, PropsWithChildren } from "react"
import Hypertext, { Props as HypertextProps } from "../buttons/Hypertext"
import Icon from "../icon/Icon"

interface Props extends PropsWithChildren {
  className?: string
  color: "danger" | "info" | "success" | "warning"
  inline?: boolean
  links?: HypertextProps[]
  title?: string
}

const colorToCls = {
  danger: "border-red-600 bg-red-50",
  info: "border-blue-600 bg-blue-50",
  success: "border-green-600 bg-green-50",
  warning: "border-orange-600 bg-yellow-100"
}

const colorToIconColor = {
  danger: "text-red-600",
  info: "text-blue-600",
  success: "text-green-600",
  warning: "text-orange-600"
}

const colorToIcon = {
  danger: "error",
  info: "info",
  success: "check",
  warning: "warning"
} as const

const InfoPanel: FC<Props> = ({ children, className, color, inline, title, links }) => {
  const stackedRender = () => (
    <aside
      className={clsx(
        "flex flex-col gap-2xs border-l-4 p-sm text-sm radius-xs",
        colorToCls[color],
        className
      )}
    >
      <div className={clsx("flex gap-xs", title ? "items-center" : "items-start")}>
        <Icon
          className={colorToIconColor[color]}
          icon={colorToIcon[color]}
          size={24}
          type="solid"
        />
        <div className={clsx(title && "text-base font-medium", "w-full")}>{title || children}</div>
      </div>
      {title && (
        <div className="flex gap-xs">
          <div className="ml-lg">{children}</div>
        </div>
      )}
      {links?.length && (
        <div className="ml-lg flex gap-sm">
          {links?.map((link, linkIndex) => <Hypertext key={linkIndex} {...link} size="small" />)}
        </div>
      )}
    </aside>
  )

  const inlineRender = () => (
    <aside
      className={clsx(
        "flex items-center justify-between gap-xs border-l-4 p-sm text-sm radius-xs",
        colorToCls[color],
        className
      )}
    >
      <div className="flex items-center gap-xs overflow-hidden">
        <Icon
          className={colorToIconColor[color]}
          icon={colorToIcon[color]}
          size={24}
          type="solid"
        />
        {title && <div className="whitespace-nowrap text-base font-medium">{title}</div>}
        <div className="overflow-hidden text-ellipsis whitespace-nowrap">{children}</div>
      </div>
      {links?.length && (
        <div className="ml-md flex gap-sm">
          {links?.map((link, linkIndex) => <Hypertext key={linkIndex} {...link} size="small" />)}
        </div>
      )}
    </aside>
  )

  return inline ? inlineRender() : stackedRender()
}

export default InfoPanel
