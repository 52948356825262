import { useSuspenseQuery } from "@tanstack/react-query"
import clsx from "clsx"
import { FC, SVGProps } from "react"
import { ErrorBoundary } from "react-error-boundary"

export const iconCommonNames = [
  "arrow-down",
  "arrow-left",
  "arrow-right",
  "arrow-up",
  "audio",
  "calendar-details",
  "calendar",
  "caret-down",
  "caret-up",
  "chart",
  "check",
  "checkbox-false",
  "checkbox-true",
  "chevron-down",
  "chevron-left",
  "chevron-right",
  "chevron-up",
  "circle-small",
  "clock",
  "cloud-arrow-down",
  "cloud-arrow-up",
  "cloud-slash",
  "code-branch",
  "credit-card",
  "cross-big",
  "cross-circle",
  "cross",
  "display-down",
  "display",
  "duplicate",
  "edit",
  "ellipsis",
  "error",
  "external",
  "globe",
  "history",
  "home",
  "info",
  "key",
  "library",
  "link",
  "list-check",
  "lock",
  "mini-setting",
  "play",
  "plus",
  "processing",
  "qrcode",
  "question-mark",
  "rotate",
  "search",
  "setting",
  "share",
  "slider",
  "transcoding",
  "trash",
  "video",
  "warning"
] as const
export const iconRegularNames = [...iconCommonNames] as const
export const iconSolidNames = [...iconCommonNames] as const
export type IconRegularName = (typeof iconRegularNames)[number]
export type IconSolidName = (typeof iconSolidNames)[number]

export type IconProps = {
  className?: string
  svgProps?: SVGProps<SVGSVGElement>
  size: 16 | 20 | 24
} & (
  | {
      type: "solid"
      icon: IconSolidName
    }
  | {
      type: "regular"
      icon: IconRegularName
    }
)

const IconElement: FC<IconProps> = ({ icon, type, className, svgProps, size }) => {
  const { data: SvgIcon } = useSuspenseQuery({
    queryFn: async () => (await import(`./icons/${type}/ic_${icon}.svg?react`)).default,
    queryKey: ["icon", type, icon]
  })

  const sizeToClass = {
    16: "w-4 h-4",
    20: "w-5 h-5",
    24: "w-6 h-6"
  }

  return (
    <div>
      <SvgIcon {...svgProps} className={clsx(sizeToClass[size], className)} />
    </div>
  )
}

const Icon: FC<IconProps> = props => (
  <ErrorBoundary
    fallbackRender={() => (
      <div className={props.className}>
        <div className="h-full w-full border border-grey-900" />
      </div>
    )}
  >
    <IconElement {...props} />
  </ErrorBoundary>
)

export default Icon
