import { Link, LinkProps } from "@tanstack/react-location"
import clsx from "clsx"
import {
  ButtonHTMLAttributes,
  forwardRef,
  AnchorHTMLAttributes,
  ForwardRefRenderFunction
} from "react"

interface BaseProps {
  color:
    | "blue-rule"
    | "blue-rule-ghost"
    | "danger"
    | "danger-outline"
    | "discreet"
    | "info"
    | "ghost"
    | "anthracite"
    | "portal"
    | "primary"
    | "secondary"
    | "success"
    | "white"
    | "warning"
  size?: "normal" | "small" | "xsmall" | "large" | "icon" | "iconTiny"
  isLoading?: boolean
  "data-testid"?: string
}
export type ButtonAsButton = BaseProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type"> & { type: "button" | "submit" }
export type ButtonAsAnchor = BaseProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "type"> & {
    type: "anchor"
    disabled?: boolean
  }
export type ButtonAsLink = BaseProps &
  Omit<LinkProps, "type"> & { type: "link"; disabled?: boolean }
export type ButtonProps = ButtonAsButton | ButtonAsAnchor | ButtonAsLink

const colorToCls = {
  anthracite: clsx("border-transparent bg-grey-700 text-white shadow-sm after:border-grey-700"),
  "blue-rule": clsx("border-transparent bg-blue-rule-800 text-white shadow-sm after:border-white"),
  "blue-rule-ghost": clsx(
    "border-transparent bg-blue-rule-800 text-grey-500 shadow-sm after:border-white"
  ),
  danger: clsx("border-transparent bg-red-600 text-white shadow-sm after:border-white"),
  "danger-outline": clsx("border-danger bg-white text-danger shadow-sm after:border-danger"),
  discreet: clsx("border-transparent text-grey-600 after:border-grey-600"),
  ghost: clsx(
    "cursor-pointer border-transparent text-grey-800 after:border-grey-600 dark:text-grey-300"
  ),
  info: clsx("border-transparent bg-info text-white shadow-sm after:border-white"),
  portal: clsx("border-transparent text-grey-200 after:border-grey-200"),
  primary: clsx("border-transparent bg-yellow-300 text-grey-900 shadow-sm after:border-grey-600"),
  "primary-outline": clsx("border-blue-300 bg-white text-blue-700 shadow-sm after:border-blue-700"),
  secondary: clsx("border-transparent bg-grey-200 text-grey-800 shadow-sm after:border-grey-700"),
  success: clsx("border-transparent bg-green-600 text-white shadow-sm after:border-white"),
  warning: clsx("border-transparent bg-orange-500 text-white shadow-sm after:border-white"),
  white: clsx("border-transparent bg-white text-grey-900 after:border-grey-700")
}

const colorToHoverCls = {
  anthracite: clsx("hover:bg-[#465062] hover:text-white"),
  "blue-rule": clsx("hover:bg-blue-rule-900 hover:text-white"),
  "blue-rule-ghost": clsx("hover:bg-blue-rule-900 hover:text-grey-100"),
  danger: clsx("hover:bg-[#e33333]"),
  "danger-outline": clsx("hover:border-[#ca1b1b] hover:text-[#ca1b1b]"),
  discreet: clsx("hover:bg-grey-200 hover:text-grey-800"),
  ghost: clsx("hover:bg-grey-200 dark:hover:bg-grey-800 dark:hover:text-grey-300"),
  info: clsx("hover:bg-[#2379ec]"),
  portal: clsx("hover:bg-grey-700 dark:hover:bg-grey-700 dark:hover:text-white"),
  primary: clsx("hover:bg-yellow-400"),
  secondary: clsx("hover:bg-[#e4ecf3]"),
  success: clsx("hover:bg-[#269e59]"),
  warning: clsx("hover:bg-[#fa8e00]"),
  white: clsx("hover:bg-grey-100 ")
}

const sizeToCls = {
  icon: clsx("h-6 w-6 text-sm leading-none radius-xs"),
  iconTiny: clsx("h-6 w-6 text-xs leading-none radius-xs"),
  large: clsx("h-14 px-8 text-size-3 radius-xs"),
  normal: clsx("text-normal h-10 px-4 radius-xs"),
  small: clsx("h-8 px-2 text-sm leading-none radius-xs"),
  xsmall: clsx("h-6 px-2 text-xs leading-none radius-xs")
}

const Button: ForwardRefRenderFunction<null, ButtonProps> = (props, ref) => {
  const disabled = props.disabled || props.isLoading
  const cls = clsx(
    "inline-flex select-none items-center justify-center whitespace-nowrap border font-medium leading-none",
    sizeToCls[props.size || "normal"],
    colorToCls[props.color],
    disabled ? "cursor-not-allowed opacity-50" : colorToHoverCls[props.color],
    props.isLoading &&
      "relative text-transparent after:absolute after:block after:h-[1.25em] after:w-[1.25em] after:animate-[spin_500ms_linear_infinite] after:border-2 after:!border-l-transparent after:!border-t-transparent after:radius-full",
    props.className
  )

  if (props.type === "anchor") {
    const { className, children, color, isLoading, size, type, ...attrs } = props
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
      <a
        ref={ref}
        className={cls}
        onClick={disabled ? e => e.preventDefault() : undefined}
        {...attrs}
      >
        {children}
      </a>
    )
  }
  if (props.type === "link") {
    const { className, children, color, isLoading, size, type, ...attrs } = props
    return (
      <Link _ref={ref} className={cls} {...attrs}>
        {children}
      </Link>
    )
  }

  const { className, children, color, isLoading, size, ...attrs } = props
  return (
    <button ref={ref} className={cls} {...attrs} disabled={disabled}>
      {children}
    </button>
  )
}

export default forwardRef(Button)
