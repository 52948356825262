import { faExternalLink } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, LinkProps } from "@tanstack/react-location"
import clsx from "clsx"
import { AnchorHTMLAttributes, ButtonHTMLAttributes, FC } from "react"

type BaseProps = {
  children: React.ReactNode
  size?: "small" | "normal"
}

type ExternalProps = BaseProps &
  Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "target" | "rel"> & {
    as?: undefined
    external: true
  }

type InternalProps = BaseProps &
  LinkProps & {
    as?: undefined
    external?: false
  }

type ButtonProps = BaseProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, "type"> & {
    as: "button"
    onClick: () => void
  }

export type Props = ExternalProps | InternalProps | ButtonProps

const Hypertext: FC<Props> = ({ children, size = "normal", className, ...props }) => {
  const cls = clsx(
    "font-medium text-grey-800 hover:text-grey-900 dark:text-white dark:hover:text-grey-200",
    size === "small" && "space-x-2xs text-sm underline",
    size === "normal" && "space-x-xs underline",
    className
  )

  if (props.as === "button") {
    return (
      <button className={cls} type="button" onClick={props.onClick}>
        <span>{children}</span>
      </button>
    )
  }

  const { external, ...rest } = props
  if (external) {
    return (
      <a className={cls} href={props.href} rel="noopener noreferrer" target="_blank" {...rest}>
        <span>{children}</span>
        <FontAwesomeIcon
          className={clsx(size === "small" && "h-3", size === "normal" && "h-4")}
          icon={faExternalLink}
        />
      </a>
    )
  }

  return (
    <Link className={cls} to={props.to} {...rest}>
      {children}
    </Link>
  )
}

export default Hypertext
