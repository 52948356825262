import clsx from "clsx"
import { FC, PropsWithChildren } from "react"

type Variant =
  | "default"
  | "legacy"
  | "legacyDialog"
  | "legacyDashboard"
  | "wizard"
  | "centered"
  | "withBackButton"

export type Props = PropsWithChildren & { className?: string } & {
  variant?: Variant
  size?: "full" | "medium" | "wide" | number // number is in rem
}

const horizontalPadding = clsx("px-6 desktop:px-12")

const variantToCls = {
  default: clsx("pb-16 desktop:py-12 fullhd:py-20"),
  legacy: clsx("pb-16 desktop:pb-12 desktop:pt-12 fullhd:pb-20 fullhd:pt-[4.5rem]"),
  legacyDashboard: clsx("pb-16 desktop:pb-12 desktop:pt-8 fullhd:pb-20"),
  legacyDialog: clsx("pb-16 desktop:pb-12 desktop:pt-10 fullhd:pb-20"),
  withBackButton: clsx("pb-16 desktop:pb-12 desktop:pt-4 fullhd:pb-20 fullhd:pt-6"),
  wizard: clsx("pb-16 desktop:pb-12 desktop:pt-6 fullhd:pb-20")
} as const

const maxWidthTocls = {
  full: "",
  medium: clsx("max-w-3xl"),
  wide: clsx("max-w-7xl")
} as const

// NB: the relative positionning below is needed to help children position absolute elements inside the container
// DO NOT REMOVE IT
const PageContainer: FC<Props> = ({ className, children, size = "full", variant = "default" }) =>
  variant === "centered" ? (
    <div className={clsx(horizontalPadding, "h-full", className)}>
      <div
        className={clsx(
          "relative mx-auto grid h-full place-items-center",
          typeof size === "string" && maxWidthTocls[size]
        )}
        style={typeof size === "number" ? { maxWidth: `${size}rem` } : undefined}
      >
        <div className={clsx("relative pb-6 pt-3 desktop:pb-12 desktop:pt-6")}>{children}</div>
      </div>
    </div>
  ) : (
    <div className={clsx(horizontalPadding, variantToCls[variant], className)}>
      <div
        className={clsx("relative mx-auto h-full", typeof size === "string" && maxWidthTocls[size])}
        style={typeof size === "number" ? { maxWidth: `${size}rem` } : undefined}
      >
        {children}
      </div>
    </div>
  )

export default PageContainer
