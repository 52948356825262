import { DefaultGenerics, PartialGenerics, useLocation, useMatches } from "@tanstack/react-location"
import { Location } from "history"
import { useEffect, useMemo } from "react"

// Dirty hack to get all matched params in a single object.
// To use when the component is not called on the matched route.
export const useMatchesParams = <TGenerics extends PartialGenerics = DefaultGenerics>() => {
  const matches = useMatches<TGenerics>()
  const params = useMemo(
    () =>
      matches.reduce(
        (result, match) => ({ ...result, ...match.params }),
        {} as TGenerics["Params"]
      ),
    [matches]
  )
  return params
}

export const usePromptWithNextLocation = (
  message: string,
  when: boolean | ((location: Location) => boolean)
) => {
  const location = useLocation()

  useEffect(() => {
    if (!when) return

    const unblock = location.history.block(transition => {
      if ((typeof when === "function" && !when(transition.location)) || window.confirm(message)) {
        unblock()
        transition.retry()
      } else {
        location.current.pathname = window.location.pathname
      }
    })

    return unblock
  }, [when, location, message])
}
