export const defaultLocale = globalThis.navigator.language.startsWith("en")
  ? globalThis.navigator.language
  : "en-GB"

export const formatNumber = new Intl.NumberFormat(defaultLocale).format

// Ex: 7200 becomes 2hrs
export const formatHours3SignificantDigits = new Intl.NumberFormat(defaultLocale, {
  maximumSignificantDigits: 3,
  style: "unit",
  unit: "hour",
  unitDisplay: "long"
}).format

export const formatNumber2SignificantDigits = new Intl.NumberFormat(defaultLocale, {
  maximumSignificantDigits: 2
}).format

export const formatNumber3SignificantDigits = new Intl.NumberFormat(defaultLocale, {
  maximumSignificantDigits: 3
}).format

// Ex: 1_234_000_000 becomes 1.23 B
export const formatCompact3SignificantDigits = new Intl.NumberFormat(defaultLocale, {
  compactDisplay: "short",
  maximumSignificantDigits: 3,
  notation: "compact"
}).format

// Ex: 1_234_000_000 becomes 1.23 G
export const formatDigital3SignificantDigits = (num: number) => {
  if (num === 0) return "0 "

  const sizes = ["", "k", "M", "G", "T", "P", "E", "Z", "Y"]
  const i = Math.floor(Math.log(num) / Math.log(1000))
  const transposedNumber = num / Math.pow(1000, i)

  return formatNumber3SignificantDigits(transposedNumber) + " " + sizes[i]
}

// See https://stackoverflow.com/a/44418732/1202385
export const formatOrdinal = (num: number) => {
  let selector

  if (num <= 0) {
    selector = 4
  } else if ((num > 3 && num < 21) || num % 10 > 3) {
    selector = 0
  } else {
    selector = num % 10
  }

  return (
    <>
      {formatNumber(num)}
      <sup>{["th", "st", "nd", "rd", ""][selector]}</sup>
    </>
  )
}

// See https://stackoverflow.com/a/44418732/1202385
export const formatOrdinalString = (num: number) => {
  let selector

  if (num <= 0) {
    selector = 4
  } else if ((num > 3 && num < 21) || num % 10 > 3) {
    selector = 0
  } else {
    selector = num % 10
  }

  return `${formatNumber(num)}${["ᵗʰ", "ˢᵗ", "ⁿᵈ", "ʳᵈ", ""][selector]}`
}

// Ex: 01/08/2021
export const formatNumericDate = new Intl.DateTimeFormat(defaultLocale, {
  day: "numeric",
  month: "numeric",
  year: "numeric"
}).format

// Ex: 9:04
export const formatTime = new Intl.DateTimeFormat(defaultLocale, {
  hour: "numeric",
  minute: "2-digit"
}).format

// Ex: 09:04
export const formatHourMinute2Digits = new Intl.DateTimeFormat(defaultLocale, {
  hour: "2-digit",
  minute: "2-digit"
}).format

// Ex: 09:04:15
export const formatHourMinuteSeconds2Digits = new Intl.DateTimeFormat(defaultLocale, {
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit"
}).format

// Ex: 1 Aug 9:04
export const formatTimeDayMonth = new Intl.DateTimeFormat(defaultLocale, {
  day: "numeric",
  hour: "numeric",
  minute: "2-digit",
  month: "short"
}).format

// Ex 1 August 2021
export const formatDateLong = new Intl.DateTimeFormat(defaultLocale, {
  day: "numeric",
  month: "long",
  year: "numeric"
}).format

// Ex 1 Aug 2021, 9:04
export const formatDateTime = new Intl.DateTimeFormat(defaultLocale, {
  day: "numeric",
  hour: "numeric",
  minute: "2-digit",
  month: "short",
  year: "numeric"
}).format

// Ex 1 Aug 2021
export const formatDate = new Intl.DateTimeFormat(defaultLocale, {
  day: "numeric",
  month: "short",
  year: "numeric"
}).format

// Ex 1 August 2021, 9:04
export const formatDateTimeLong = new Intl.DateTimeFormat(defaultLocale, {
  day: "numeric",
  hour: "numeric",
  minute: "2-digit",
  month: "long",
  year: "numeric"
}).format

// Ex: 01/08/2021, 9:04:31
export const formatNumericDateTime = new Intl.DateTimeFormat(defaultLocale, {
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  month: "numeric",
  second: "numeric",
  year: "numeric"
}).format

// Ex: 01/08/2021, 9:04:31
export const formatFullDate = new Intl.DateTimeFormat(defaultLocale, {
  dateStyle: "full"
}).format

// Ex: 1 Aug
export const formatDayMonth = new Intl.DateTimeFormat(defaultLocale, {
  day: "numeric",
  month: "short"
}).format

// Ex: 1 Aug 2025
export const formatDayMonthYear = new Intl.DateTimeFormat(defaultLocale, {
  day: "numeric",
  month: "short",
  year: "numeric"
}).format

// Ex: 1 August
export const formatDayLongMonth = new Intl.DateTimeFormat(defaultLocale, {
  day: "numeric",
  month: "long"
}).format

// Ex: August
export const formatLongMonth = new Intl.DateTimeFormat(defaultLocale, {
  month: "long"
}).format

// Ex: Aug 2021
export const formatMonthYear = new Intl.DateTimeFormat(defaultLocale, {
  month: "short",
  year: "numeric"
}).format

// Ex: August 2021
export const formatLongMonthYear = new Intl.DateTimeFormat(defaultLocale, {
  month: "long",
  year: "numeric"
}).format

// Ex Friday, 31 Mar, 9:04
export const formatDateTimeCharts = new Intl.DateTimeFormat(defaultLocale, {
  day: "numeric",
  hour: "numeric",
  minute: "2-digit",
  month: "short",
  weekday: "long"
}).format

// Ex: 08/23. NB: forcing locale to en-GB is intended here. We want to stick on MM/YY format
export const formatCardExpirationDate = new Intl.DateTimeFormat("en-GB", {
  month: "2-digit",
  year: "2-digit"
}).format

// Ex: 12.3 kb/s
export const formatKilobitPerSecond = new Intl.NumberFormat(defaultLocale, {
  maximumFractionDigits: 3,
  style: "unit",
  unit: "kilobit-per-second",
  unitDisplay: "short"
}).format

// Ex: 123 Kmin
export const formatMinutesCompact3SignificantDigits = new Intl.NumberFormat(defaultLocale, {
  compactDisplay: "short",
  maximumSignificantDigits: 3,
  notation: "compact",
  style: "unit",
  unit: "minute",
  unitDisplay: "short"
}).format

// Ex: 12%
export const formatPercentNoFraction = new Intl.NumberFormat(defaultLocale, {
  maximumFractionDigits: 0,
  style: "percent"
}).format

// Ex: 12.5%
export const formatPercent3SignificantDigits = new Intl.NumberFormat(defaultLocale, {
  maximumSignificantDigits: 3,
  style: "percent"
}).format

// Ex: 0.75 secs
export const formatFractionalSeconds = new Intl.NumberFormat(defaultLocale, {
  style: "unit",
  unit: "second",
  unitDisplay: "short"
}).format

// Ex: 4,500 ms
export const formatMilliseconds = new Intl.NumberFormat(defaultLocale, {
  style: "unit",
  unit: "millisecond",
  unitDisplay: "short"
}).format

// Ex: 12 secs, 12.3K secs
export const formatSeconds = new Intl.NumberFormat(defaultLocale, {
  compactDisplay: "short",
  maximumFractionDigits: 0,
  maximumSignificantDigits: 3,
  notation: "compact",
  style: "unit",
  unit: "second",
  unitDisplay: "short"
}).format

// Ex: 12 secs, 12,000 secs
export const formatSecondsStandard = new Intl.NumberFormat(defaultLocale, {
  compactDisplay: "short",
  style: "unit",
  unit: "second",
  unitDisplay: "short"
}).format

// Ex: 12 seconds, 12,000 seconds
export const formatSecondsStandardLongUnit = new Intl.NumberFormat(defaultLocale, {
  compactDisplay: "short",
  style: "unit",
  unit: "second",
  unitDisplay: "long"
}).format

// Ex: 5 mins, 35.7K mins
export const formatMinutes = new Intl.NumberFormat(defaultLocale, {
  compactDisplay: "short",
  maximumSignificantDigits: 3,
  notation: "compact",
  style: "unit",
  unit: "minute",
  unitDisplay: "short"
}).format

// Ex: 3 hrs, 3.4K hrs
export const formatHours = new Intl.NumberFormat(defaultLocale, {
  compactDisplay: "short",
  maximumSignificantDigits: 3,
  notation: "compact",
  style: "unit",
  unit: "hour",
  unitDisplay: "short"
}).format

// Ex: "1 hour" or "3 hours"
export const formatLongHours = new Intl.NumberFormat(defaultLocale, {
  style: "unit",
  unit: "hour",
  unitDisplay: "long"
}).format

// Ex: "7 days", "1,095 days"
export const formatDays = new Intl.NumberFormat("en-GB", {
  style: "unit",
  unit: "day"
}).format

// Ex:
// 12 secs
// 5 mins
// 5 mins 12 secs
// 10 mins
// 35.7K mins
export const formatStatsDuration = (duration: number) => {
  if (duration < 60) return formatSeconds(duration)
  if (duration < 6000 /* 100 minutes */)
    return (
      formatMinutes(Math.floor(duration / 60)) +
      (duration % 60 > 0 ? " " + formatSeconds(duration % 60) : "")
    )
  return formatMinutes(Math.round(duration / 60))
}

// Ex:
// 12 secs
// 5 mins 12 secs
// 3 hrs 5 mins 12 secs
// 7 days 3 hrs 5 mins 12 secs
export const formatDuration = (duration: number) => {
  let timeLeft = duration
  const days = Math.floor(timeLeft / 86400)
  timeLeft = timeLeft % 86400
  const hours = Math.floor(timeLeft / 3600)
  timeLeft = timeLeft % 3600
  const minutes = Math.floor(timeLeft / 60)
  timeLeft = timeLeft % 60
  const seconds = timeLeft

  return [
    days > 0 && formatDays(days),
    hours > 0 && formatHours(hours),
    minutes > 0 && formatMinutes(minutes),
    seconds > 0 && formatFractionalSeconds(seconds)
  ]
    .filter(Boolean)
    .join(" ")
}

// Ex: "Motorcycle, Bus, and Car"
export const formatListAnd = (tokens: string[]) =>
  new Intl.ListFormat(defaultLocale, {
    style: "long",
    type: "conjunction"
  }).format(tokens)

export const formatListOr = (tokens: string[]) =>
  new Intl.ListFormat(defaultLocale, {
    style: "long",
    type: "disjunction"
  }).format(tokens)

export const decimalSeparator =
  Intl.NumberFormat(defaultLocale)
    .formatToParts(1.1)
    .find(part => part.type === "decimal")?.value || "."

export const thousandsSeparator =
  Intl.NumberFormat(defaultLocale)
    .formatToParts(1000)
    .find(part => part.type === "group")?.value || ""

// Ex: "eng => English"
export const formatLanguage = (iso639LangCode: string) => {
  try {
    return new Intl.DisplayNames(["en"], { type: "language" }).of(iso639LangCode)
  } catch {
    return iso639LangCode
  }
}
