import { DialogTitle } from "@headlessui/react"
import { FC, PropsWithChildren, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import Button from "src/components/base/buttons/Button"
import Input from "src/components/base/forms/Input"
import Icon from "src/components/base/icon/Icon"
import Modal from "src/components/base/modals/Modal"
import ModalContent from "src/components/base/modals/ModalContent"

export interface ModalConfirmationProps extends PropsWithChildren {
  cancelBtnLabel?: string
  message?: string
  okBtnLabel?: string
  onAccept: () => Promise<void> | void
  onClose: () => void
  show: boolean
  title: string
  confirmValue?: string
  variant?: "default" | "warning" | "destructive"
}

const variantToIcon = {
  default: undefined,
  destructive: "error",
  warning: "warning"
} as const

const variantToIconClass = {
  default: undefined,
  destructive: "text-red-600",
  warning: "text-orange-600"
} as const

const variantToCancelBtnLabel = {
  default: "Cancel",
  destructive: "Cancel",
  warning: "Cancel"
}

const variantToOkBtnLabel = {
  default: "Apply",
  destructive: "Delete",
  warning: "Apply"
}

const variantToOkBtnColor = {
  default: "primary",
  destructive: "danger",
  warning: "primary"
} as const

type FormSchema = { confirmValue?: string }

const ModalConfirmation: FC<ModalConfirmationProps> = ({
  cancelBtnLabel,
  confirmValue,
  children,
  message,
  okBtnLabel,
  onAccept,
  onClose,
  show,
  variant = "default",
  title
}: ModalConfirmationProps) => {
  const hasIcon = !!variantToIcon[variant]
  const [isLoading, setIsLoading] = useState(false)
  const form = useForm<FormSchema>()

  const onSubmit = form.handleSubmit(async () => {
    setIsLoading(true)
    try {
      await onAccept()
      onClose()
    } finally {
      setIsLoading(false)
    }
  })

  return (
    <Modal show={show} onDismiss={onClose}>
      <ModalContent className="relative m-md w-[calc(100vw-2rem)] max-w-xl p-md">
        <div className="mb-md flex items-center justify-between gap-md align-middle">
          <DialogTitle className="title !mb-0 flex items-center gap-xs">
            {hasIcon && (
              <Icon
                className={variantToIconClass[variant]!}
                icon={variantToIcon[variant]!}
                size={24}
                type="solid"
              />
            )}
            {title}
          </DialogTitle>
          <Button
            aria-label="Close"
            className="!px-0.5 !text-grey-600"
            color="ghost"
            size="xsmall"
            type="button"
            onClick={onClose}
          >
            <Icon icon="cross-big" size={16} type="solid" />
          </Button>
        </div>

        <form id="confirmModalForm" noValidate onSubmit={onSubmit}>
          <FormProvider {...form}>
            {children || message}

            {confirmValue && (
              <Input {...form.register("confirmValue")} autoFocus className="mt-sm" />
            )}
          </FormProvider>

          <div className="mt-md flex items-center justify-end gap-sm align-middle">
            <Button aria-label="Cancel" color="secondary" type="button" onClick={onClose}>
              {cancelBtnLabel || variantToCancelBtnLabel[variant]}
            </Button>
            <Button
              aria-label="Apply"
              autoFocus
              color={variantToOkBtnColor[variant]}
              disabled={!!confirmValue && confirmValue !== form.watch("confirmValue")?.trim()}
              isLoading={isLoading}
              type="submit"
            >
              {okBtnLabel || variantToOkBtnLabel[variant]}
            </Button>
          </div>
        </form>
      </ModalContent>
    </Modal>
  )
}

export default ModalConfirmation
