import "src/styles/legacy.scss"
import "src/styles/toastify.scss"
import "src/styles/index.css"
import { Auth0Provider } from "@auth0/auth0-react"
import { captureException, init } from "@sentry/browser"
import { Outlet, ReactLocation, Router } from "@tanstack/react-location"
import { QueryClientProvider, useSuspenseQuery } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { lazy, Suspense, useEffect } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { ToastContainer } from "react-toastify"
import Loader from "src/components/base/misc/Loader"
import { ModalConfirmationProvider } from "src/components/base/modals/ModalConfirmationContext"
import NoTrailingSlash from "src/components/utils/NoTrailingSlash"
import ErrorFallback from "src/components/wired/misc/ErrorFallback"
import { getEnv } from "src/helpers/env"
import { queryClient } from "src/queries/client"
import { disabledQuery } from "src/queries/specials"
import { ApiError } from "src/services/api"
import { useImpersonificationStore } from "src/stores/impersonification"
import { useKeyboardDetection } from "src/stores/keyboard"
import AuthenticationRequired from "../components/utils/AuthenticationRequired"
import { WithErrorBoundary } from "../components/utils/WithErrorBoundary"
import { useRelease } from "../queries/release"
import { portalTree } from "./routes"

const FromBackOfficeBorder = lazy(() => import("../components/base/misc/FromBackOfficeBorder"))
const Logout = lazy(() => import("../components/utils/Logout"))

// Configuring sentry's dynamic options.
const sentryDsn = import.meta.env.REACT_APP_SENTRY_DSN

if (sentryDsn) {
  init({
    beforeSend(event) {
      const exception = event.exception?.values?.[0]

      if (exception) {
        const message = exception.value || ""

        if (
          message.includes("preload") ||
          message.startsWith("Failed to fetch dynamically imported module")
        ) {
          event.fingerprint = ["network-error", "dynamic-import"]
        }
      }

      return event
    },
    dsn: sentryDsn,
    environment: getEnv(),
    release: import.meta.env.REACT_APP_SENTRY_RELEASE
  })
}

const reactLocation = new ReactLocation()

const getAuth0ClientId = (isImpersonified: boolean) => {
  const backOfficeId = import.meta.env.REACT_APP_AUTH0_CLIENT_ID_QX_BACKOFFICE
  const appId = import.meta.env.REACT_APP_AUTH0_CLIENT_ID
  return isImpersonified ? backOfficeId : appId
}

const routes = [{ element: <Logout />, path: "logout" }, portalTree]

const App = () => {
  useKeyboardDetection()
  const isImpersonified = useImpersonificationStore(state => !!state.operatorUuid)

  return (
    <ErrorBoundary
      fallbackRender={({ error }) => <Loader error={error} isCover />}
      onError={error => {
        if (!(error instanceof ApiError)) {
          captureException(error)
        }
      }}
    >
      <Suspense fallback={<Loader isCover />}>
        <Auth0Provider
          authorizationParams={{
            audience: import.meta.env.REACT_APP_AUTH0_AUDIENCE,
            redirect_uri: window.location.origin
          }}
          cacheLocation={isImpersonified ? "memory" : "localstorage"}
          clientId={getAuth0ClientId(isImpersonified)}
          domain={import.meta.env.REACT_APP_AUTH0_DOMAIN}
          issuer={import.meta.env.REACT_APP_AUTH0_ISSUER}
          useFormData
          useRefreshTokens
          useRefreshTokensFallback
          onRedirectCallback={appState => {
            const returnTo = appState?.returnTo
            if (returnTo) reactLocation.history.push(returnTo)
          }}
        >
          <QueryClientProvider client={queryClient}>
            <CheckRelease />
            <AuthenticationRequired>
              <ToastContainer
                className="mt-10"
                closeButton={({ closeToast }) => <button className="delete" onClick={closeToast} />}
                closeOnClick={false}
                hideProgressBar
                icon={false}
                pauseOnFocusLoss
                pauseOnHover
                theme="colored"
              />
              <Router location={reactLocation} routes={routes}>
                <NoTrailingSlash />
                <ModalConfirmationProvider>
                  <WithErrorBoundary ErrorFallback={ErrorFallback}>
                    <Outlet />
                    {isImpersonified && <FromBackOfficeBorder />}
                  </WithErrorBoundary>
                </ModalConfirmationProvider>
              </Router>
            </AuthenticationRequired>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </Auth0Provider>
      </Suspense>
    </ErrorBoundary>
  )
}

const build = {
  date: import.meta.env.BUILD_DATE as string | undefined,
  id: import.meta.env.BUILD_ID as string | undefined
}

const CheckRelease = () => {
  const releaseQuery = useRelease()
  const { data: release } = useSuspenseQuery(build.date ? releaseQuery : disabledQuery)

  useEffect(() => {
    if (release && (release.build.id !== build.id || release.build.date !== build.date)) {
      console.info("Local build not matching remote build. Reloading…", {
        local: build,
        remote: release.build
      })
      window.location.reload()
    }
  })

  return null
}

export default App
